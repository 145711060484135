section {
    flex: 1 0 auto;
    position: relative;
    width: 100%;
    transition: all .5s ease-in;
}

.who_am_I {
    font-family: Cinzel;
}

.has-first-color {
    color: var(--primary-color);
}

.btn-portfolio {
    background: var(--primary-color);
    border-radius: 0;
}

.btn-portfolio a {
    color: #000;
    text-decoration: none;
}

.btn-about a {
    color: var(--text-color);
    text-decoration: none;
}

.intro_sec {
    height: calc(100vh - 60px);
    min-height: 700px;
    height: 100vh;
    margin-top: -60px;
}

@media (max-width:991.98px) {
    .intro_sec {
        display: block;
        height: auto!important;
    }
}

.intro_sec .text,
.intro_sec .h_bg-image {
    width: 50%;
}

@media (max-width:991.98px) {
    .intro_sec .text,
    .intro_sec .h_bg-image {
        width: 100%;
    }
}

.intro_sec .intro {
    max-width: 450px;
    margin: 0 auto;
}

@media (max-width:991.98px) {
    .intro_sec .intro {
        max-width: 700px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.intro_sec .intro .feature .wrap-icon {
    background: 0 0!important;
    width: auto;
    height: auto;
    margin-bottom: 0;
}

.intro_sec .intro .feature .wrap-icon svg {
    color: #5cccc9;
}

.intro_sec .text h1 {
    font-size: 30px;
    margin-bottom: 50px;
    font-weight: 700;
}

.intro_sec .text h3 {
    font-size: 16px;
    font-weight: 700;
}

.intro_sec .h_bg-image {
    background-size: cover;
    background-position: center;
    min-height: 700px;
    position: relative;
}

.ac_btn {
    padding: 4px 19px;
    color: var(--secondary-color);
    position: relative;
    border: var(--secondary-color) 2px solid;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    cursor: pointer;
    border-radius: 0;
    margin-right: 20px;
}

.ac_btn a {
    text-decoration: none;
}

.ac_btn:hover {
    box-shadow: 8px 8px 0px var(--text-color), -8px -8px 0px var(--text-color);
}

.ac_btn:hover .one {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .two {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .three {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .four {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
}

.ac_btn .ring {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    -webkit-transform: translate3d(0px, 90px, 0px);
            transform: translate3d(0px, 90px, 0px);
}

.ac_btn .one {
    background-color: #000;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
    z-index: -4;
}

.ac_btn .two {
    background-color: var(--primary-color);
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
}

.ac_btn .three {
    background-color: var(--secondary-color);
    z-index: -2;
    transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
}

#button_p {
    background: var(--secondary-color);
    color: var(--primary-color);
}

#button_h:hover {
    color: var(--primary-color)
}

.intro_sec .h_bg-image .block {
    bottom: 50vh;
    max-width: 220px;
    padding: 30px;
    left: -110px;
    position: absolute;
    width: 250px;
    height: 220px;
    border-radius: 100%;
    /* mix-blend-mode: multiply; */
    background-color: #2f514e;
    z-index: 9999999999;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

@media (max-width:991.98px) {
    .intro_sec .h_bg-image {
        height: 600px;
        min-height: 75vh;
        margin-bottom: 30px;
    }
    .intro_sec .h_bg-image .block {
        top: unset;
        bottom: 0;
        width: 100%;
        margin: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 50%);
                transform: translate(-50%, 50%);
    }
}

.intro_sec .h_bg-image .block .wrap-icon {
    font-size: 40px;
    color: var(--text-color);
    margin-bottom: 10px;
    display: block;
}

.intro_sec .h_bg-image .block h3 {
    font-size: 14px;
    color: var(--text-color);
    letter-spacing: .1rem;
    font-weight: 700;
    margin-bottom: 15px;
}

.intro_sec .h_bg-image .block p {
    font-size: 14px;
    line-height: 1.3;
    color: var(--text-color)
}

.intro_sec .h_bg-image {
    -webkit-filter: saturate(0.5);
            filter: saturate(0.5);
}
.site__header {
    top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.menu__button {
    color: var(--text-color);
}

.menu__button:focus,
.menu__button:hover {
    color: var(--text-color);
    box-shadow: unset;
}

.menu__button svg {
    width: 2em;
    height: 2em;
    fill: var(--text-color-2);
    color: var(--text-color-2);
}

.nav_ac {
    padding: 5px 15px;
    margin: 0;
    border: unset;
    background: var(--primary-color);
    font-size: 1.25rem;
    font-family: Marcellus;
    color: var(--text-color-2);
    line-height: 2;
    height: 50px;
    font-weight: bold;
    z-index: 1000;
}

.nav_ac:hover {
    color: var(--text-color-2);
}

.br-top,
.br-bottom,
.br-right,
.br-left {
    position: fixed;
    z-index: 999999;
    background: var(--primary-color);
}

.br-top {
    top: 0;
    height: 10px;
    left: 0;
    width: 100%;
}

.br-bottom {
    bottom: 0;
    left: 0;
    height: 10px;
    width: 100%;
}

.br-right {
    width: 10px;
    right: 0;
    top: 0;
    height: 100%;
}

.br-left {
    width: 10px;
    left: 0;
    top: 0;
    height: 100%;
}

.cortina__wrapper-menu {
    position: relative;
    width: 100%;
    padding-top: 5em;
    padding-bottom: 3em;
    height: 100%;
    overflow-y: auto;
}

.site__navigation {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    visibility: hidden;
}

.menu__opend {
    visibility: visible !important;
}

.main__menu_ul,
.menu_right {
    opacity: 0;
    position: relative;
    transition: 0.5s;
    transition-delay: 0s;
    visibility: hidden;
    z-index: 100;
}

.menu_right {
    text-align: center;
}

.site__navigation.menu__opend .main__menu_ul,
.site__navigation.menu__opend .menu_right {
    opacity: 1;
    transition-delay: 0.6s;
    visibility: visible;
}

.site__navigation .main__menu_ul li {
    list-style: none;
    margin: 10px 0;
}

.site__navigation .main__menu_ul li a {
    color: var(--text-color);
    display: block;
    font-size: 2.5rem;
    text-decoration: none;
}

.bg__menu {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    will-change: transform;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    transition: .5s ease all;
}

.menu__opend .bg__menu {
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.menu__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden auto;
}

.the_menu {
    padding-top: 20vh;
    padding-bottom: 20vh;
    padding-left: 0;
}

@media (min-width: 992px) {
    .menu__container {
        margin-left: 33.3333%;
    }
    .the_menu {
        padding-top: 10vh;
        padding-bottom: 10vh;
    }
}

.the_menu .menu_item>a {
    color: var(--text-color-2);
    line-height: 1;
    font-size: 2rem;
    display: inline-block;
    position: relative;
    transition: color 250ms cubic-bezier(0, 0, 0.58, 1) 0s;
    padding: 4px 0px;
    font-family: Marcellus;
}

@media (min-width: 768px) {
    .the_menu .menu_item>a {
        font-size: 4.8vw;
    }
}

.menu_footer {
    bottom: 0;
    font-family: Marcellus;
    font-size: 14px;
    background: var(--primary-color);
}

.menu_footer a {
    color: var(--text-color-2);
    margin-right: 10px;
}
@media only screen and (max-width: 991px) {
    .s_c {
        padding-top: 40px;
    }
}

.page-enter {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
}

.page-enter-active {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    transition: all 400ms ease-out;
}

.page-exit {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.page-exit-active {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    -webkit-transform: translateY(-130%);
            transform: translateY(-130%);
    transition: all 400ms ease-out;
}
:root {
    --bg-color: #0c0c0c;
    --primary-color: #0d0d0d;
    --secondary-color: #fff;
    --text-color: #fff;
    --text-color-2: #fff;
    --overlay-color: rgb(12 12 12 / 63%);
}

[data-theme="light"] {
    --bg-color: #ffffff;
    --primary-color: #ffffff;
    --secondary-color: #000;
    --text-color: #000;
    --text-color-2: #000;
    --overlay-color: rgb(255 255 255 / 70%);
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    background-color: #0c0c0c;
    background-color: var(--bg-color);
    color: #fff;
    color: var(--text-color);
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: 60px;
    border-left: 10px solid #0d0d0d;
    border-left: 10px solid var(--primary-color);
    border-right: 10px solid #0d0d0d;
    border-right: 10px solid var(--primary-color);
}

ul {
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Marcellus;
}

a,
a:hover {
    color: #fff;
    color: var(--text-color);
}

p {
    word-break: break-word;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

.ovhidden {
    overflow: hidden;
}

.text_2,
.text_2:hover {
    color: #fff;
    color: var(--text-color-2);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor__dot div {
    z-index: 999999 !important;
}

.cursor__dot div:last-child {
    -webkit-filter: invert(1);
            filter: invert(1);
    background-color: #0d0d0d !important;
    background-color: var(--primary-color) !important;
}

.cursor__dot div:first-child {
    -webkit-filter: invert(1);
            filter: invert(1);
    background-color: rgb(12 12 12 / 63%) !important;
    background-color: var(--overlay-color) !important;
}

.color_pr {
    color: #0d0d0d !important;
    color: var(--primary-color) !important;
}

.color_sec {
    color: #fff;
    color: var(--secondary-color);
}
